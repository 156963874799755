import React, { useState, useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { v4 as uuidv4 } from 'uuid'
import Modal from '../../../UI/Modal'
import { getBuyingClientsQuery } from '../../../../apollo/query/clients'
import { addSuccessAlert } from '../../../../utils/helpers/alerts'
import { handleApolloError } from '../../../../utils/errors'
import { sortOnKey } from '../../../../utils/format'
import { getProductsQuery } from '../../../../apollo/query/products'
import {
  addPurchaseMutation,
  getSellerTransactionsQuery,
} from '../../../../apollo/query/transactions'

const AddTransactionModal = ({ id: modalId, appendTo }) => {
  const [key, setKey] = useState(uuidv4())
  const [product, setProduct] = useState(null)
  const [values, setValues] = useState({
    soldTo: null,
    productId: null,
    licenses: null,
  })
  const { data, loading, error } = useQuery(getProductsQuery)
  const { data: buyingClients, error: buyingClientsError } = useQuery(
    getBuyingClientsQuery
  )

  const addTransactionSuccessAlert = () =>
    addSuccessAlert({ title: 'Transaction added' })

  const [addPurchase] = useMutation(addPurchaseMutation, {
    update: (cache, { data: { addPurchase } }) => {
      const { purchases } = cache.readQuery({
        query: getSellerTransactionsQuery,
      })
      if (purchases && addPurchase.purchase) {
        cache.writeQuery({
          query: getSellerTransactionsQuery,
          data: {
            purchases: [...purchases, addPurchase.purchase],
          },
        })
      }
    },
    onCompleted: addTransactionSuccessAlert,
    onError: handleApolloError,
  })

  useEffect(() => {
    if (data) setProduct(data.products.find((p) => p.id === values.productId))
  }, [values.productId])

  if (error || buyingClientsError) throw new Error(error.message)

  if (loading || !data || !buyingClients)
    return (
      <Modal
        id={modalId}
        appendTo={appendTo}
        useDefaultButtons
        closeOnEscape={false}
      />
    )

  const getProducts = () => {
    const options = []
    options.push(
      <option disabled key="selectProduct" value="">
        Select product
      </option>
    )

    if (!data.products.length) return options

    const temp = data['products'].reduce((a, product) => {
      if (product.state === 'READY') {
        a.push(product)
      }
      return a
    }, [])

    const o = sortOnKey(temp, 'name', false).map((product) => (
      <option key={product.id} value={product.id}>
        {product.name}
      </option>
    ))

    return [...options, ...o]
  }

  const getClients = () => {
    if (!buyingClients.clients) return []
    const temp = sortOnKey([...buyingClients.clients], 'name', false)
    return temp.reduce((a, i) => {
      a.push(
        <option key={i.id} value={i.id}>
          {i.name}
        </option>
      )
      return a
    }, [])
  }
  const clear = () => {
    setTimeout(() => {
      setKey(uuidv4())
      setValues({ soldTo: null, productId: null, licenses: null })
    }, 300)
  }
  const validateFields = (e) => {
    const { productId, soldTo, licenses } = values
    const licensesValid = product.licenseType === 'UNLIMITED' || licenses
    if (!productId)
      document.querySelector('#select-product').classList.add('input--required')
    if (!soldTo)
      document.querySelector('#sold-to').classList.add('input--required')
    if (!licensesValid)
      document.querySelector('#licenses-sold').classList.add('input--required')

    if (!productId || !soldTo || !licensesValid) {
      e.preventDefault()
      e.stopPropagation()
      return false
    }
    return true
  }
  const submit = (e) => {
    const isValid = validateFields(e)
    if (isValid) {
      const variables = {
        buyingClientId: values.soldTo,
        productId: values.productId,
        purchasedLicenseCount: values.licenses,
      }
      addPurchase({
        variables,
      })
      setTimeout(() => clear(), 300)
    }
  }

  const getSupportEmailLink = () => (
    <a className="u-color-stable-dark" href="mailto:support@warpvr.com">
      support@warpvr.com
    </a>
  )

  return (
    <Modal
      id={modalId}
      appendTo={appendTo}
      useDefaultButtons
      closeOnEscape={false}
      cancelHandler={clear}
      submitHandler={submit}
      headerText="New transaction">
      <form className="mt-3" key={key}>
        <div className="cell small-12">
          <label
            htmlFor="select-product"
            defaultValue="selectProduct"
            className="c-form__label--required">
            Select product
          </label>
          <select
            name="select"
            id="select-product"
            value={values.productId || ''}
            required
            onChange={({ target }) => {
              setValues({ ...values, productId: target.value })
            }}>
            {getProducts()}
          </select>
        </div>
        <div className="row">
          <div className="cell small-6 pr-1">
            <label htmlFor="sold-to" className="c-form__label--required">
              Sold to
            </label>
            <select
              name="select"
              value={values.soldTo || ''}
              id="sold-to"
              required
              onChange={({ target }) =>
                setValues({ ...values, soldTo: target.value })
              }>
              <option disabled value="">
                Select buyer
              </option>
              {getClients()}
            </select>
          </div>
          {product && product.licenseType !== 'UNLIMITED' && (
            <div className="cell small-6 pl-1">
              <label
                htmlFor="licenses-sold"
                className="c-form__label--required">
                Licenses sold
              </label>
              <input
                type="number"
                name="licenses"
                id="licenses-sold"
                value={values.licenses || ''}
                required
                onChange={({ target }) =>
                  setValues({ ...values, licenses: Number(target.value) })
                }
              />
            </div>
          )}
        </div>
      </form>
      <p>Contact {getSupportEmailLink()} when a workspace is not listed</p>
    </Modal>
  )
}
export default AddTransactionModal
