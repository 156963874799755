import React from 'react'
import { default as S } from '../../../utils/lang/en.json'
import { dateFormat } from '../../../utils/format'
import scenarioBg from '../../../../../images/default/scenario.png'

const PurchasedProductCards = ({
  products,
  setCurrentProduct,
  setShowLicenseTab,
}) => {
  const getSubtitle = (product) => {
    return `${
      product.courses.length
        ? `${product.courses.length} course${
            product.courses.length > 1 ? 's' : ''
          }`
        : ''
    }${product.courses.length && product.scenarios.length ? ', ' : ''} ${
      product.scenarios.length
        ? `${product.scenarios.length} scenario${
            product.scenarios.length > 1 ? 's' : ''
          }`
        : ''
    } `
  }

  const getTotalCount = (purchases) => {
    return purchases.reduce(
      (a, b) => {
        const x = {
          availableLicenseCount:
            a.availableLicenseCount + b.availableLicenseCount,
          activeLicenseCount: a.activeLicenseCount + b.activeLicenseCount,
          usedLicenseCount: a.usedLicenseCount + b.usedLicenseCount,
          expiredLicenseCount: a.expiredLicenseCount + b.expiredLicenseCount,
        }
        const sum =
          x.availableLicenseCount +
          x.activeLicenseCount +
          x.usedLicenseCount +
          x.expiredLicenseCount
        return { ...x, sum }
      },
      {
        availableLicenseCount: 0,
        activeLicenseCount: 0,
        usedLicenseCount: 0,
        expiredLicenseCount: 0,
      }
    )
  }

  const getLicenseInfo = (product) => {
    const {
      kind,
      state,
      expirationDate,
      licenseType,
      licenseDuration: productLicenseDuration,
    } = product.purchases[0]

    const topText = () => {
      if (licenseType === 'UNLIMITED') {
        return 'Unlimited licenses'
      } else {
        return `${
          getTotalCount(product.purchases).availableLicenseCount
        } licenses available`
      }
    }

    const subText = () => {
      if (kind === 'SUBSCRIPTION') {
        const { licenseDuration, renewalDate, renewalLicenseCount, state } =
          product.subscriptions[0]

        const date = dateFormat(renewalDate, 'DD MMM YYYY')

        if (state === 'ACTIVE') {
          return `${
            S.store.licenseType[licenseType]
          } license (${licenseDuration} month${
            licenseDuration === 1 ? '' : 's'
          }) • Renews ${date} with ${renewalLicenseCount} licenses`
        } else {
          return `${
            S.store.licenseType[licenseType]
          } license (${licenseDuration} month${
            licenseDuration === 1 ? '' : 's'
          }) • Cancelled at ${date}`
        }
      }

      if (kind === 'ONE_TIME') {
        return `${
          S.store.licenseType[licenseType]
        } license (${productLicenseDuration} month${
          productLicenseDuration === 1 ? '' : 's'
        })`
      }
    }

    return (
      <>
        <p className="text-dark" style={{ marginBottom: '5px' }}>
          {topText()}
        </p>
        <small className="text-stable-dark" style={{ fontWeight: '500' }}>
          <p className="mb-0" style={{ fontSize: '12px', lineHeight: '1.6' }}>
            {subText()}
          </p>
        </small>
      </>
    )
  }
  return products.map((product) => {
    return (
      <div
        id={product.id}
        key={product.id}
        className="o-card__purchase cell cursor-pointer small-12 medium-6 large-4 xxlarge-3 xxxlarge-2"
        onClick={() => {
          setCurrentProduct(product)
        }}
        data-open="product-info-modal">
        <div className="card o-card o-card--overflow">
          <div
            className="o-card--image container with-ratio-4-3"
            onClick={() => setShowLicenseTab(false)}>
            <div className="aspect-ratio">
              <img
                src={product.imageUrl || scenarioBg}
                className="cursor-pointer"
              />
              <div className="image-overlay--top-left">
                <span className="shadow-container--top"></span>
                <div
                  className="o-card__company--image"
                  style={{
                    backgroundImage: `url("${product.client.logoMobileUrl}")`,
                  }}></div>
                <div className="o-card__company--name ml-1">
                  {product.client.name}
                </div>
              </div>
            </div>
          </div>
          <div
            onClick={() => setShowLicenseTab(false)}
            className="o-card__purchase--title grid-x grid-x-padding">
            <div className="cell auto mb-1">
              <div className="card-devider o-card--title">
                <h2 className="text-truncate mb-0">{product.name}</h2>
              </div>
              <h4 className="o-card--content pl-0 text-stable-dark">
                {getSubtitle(product)}
              </h4>
            </div>
          </div>
          <div
            onClick={() => setShowLicenseTab(true)}
            className="o-card__purchase--license">
            {getLicenseInfo(product)}
          </div>
        </div>
      </div>
    )
  })
}
export default PurchasedProductCards
