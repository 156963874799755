import React from 'react'
import { useMutation } from '@apollo/client'
import Modal from '../../../UI/Modal'
import { addSuccessAlert } from '../../../../utils/helpers/alerts'
import { handleApolloError } from '../../../../utils/errors'
import { cancelPurchaseSubscriptionMutation } from '../../../../apollo/query/transactions'

const CancelSubscriptionModal = ({ id: modalId, appendTo, purchaseId }) => {
  const [cancelSubscription] = useMutation(cancelPurchaseSubscriptionMutation, {
    onCompleted: () => addSuccessAlert({ title: 'Subscription cancelled' }),
    onError: handleApolloError,
  })

  const submit = () => {
    cancelSubscription({
      variables: {
        id: purchaseId,
      },
    })
  }

  return (
    <Modal
      id={modalId}
      appendTo={appendTo}
      useDefaultButtons
      closeOnEscape={false}
      submitHandler={submit}
      headerText="Are you sure you want to cancel this subscription"
      subtitle="This action can not be undone"
      textAssertive
      submitButtonText="Yes, I'm sure">
      <p className="mt-3">
        When this subscription is stopped, no future transactions will be added
        for this client, Any available or active licenses will not be deleted.
      </p>
    </Modal>
  )
}
export default CancelSubscriptionModal
