import React from "react"
import { htmlToText } from "../../../../utils/helpers/javascript"
import TimerDropdowns from "./TimerDropdowns"
import TransparentInput from "./TransparentInput"

const ElementInput = ({element, handleChange}) => {
  if (element.kind === 'TRANSITION') return <div className="pb-0-5" />

  if (element.kind === 'TIMER')
    return (
      <div className="pb-1 text-normal flex-container align-middle align-justify">
        <span>Set timer to</span>
        <TimerDropdowns element={element} />
      </div>
    )

  const getPlaceholder = () => {
    if (element.groupKind === 'MEDIAPANEL') {
      if (element.kind === 'TOOLTIP') return 'Add a tooltip...'
      return 'Add a title...'
    }

    return 'Add a label...'
  }

  return (
    <div className="pb-1 -ml-1">
      <TransparentInput
        id={`element-${element.id}`}
        name="label"
        onConfirm={handleChange}
        placeholder={getPlaceholder()}
        // remove <br> and other tags from the label => old bug
        value={htmlToText(element.label) ?? ''}
        type="contentEditable"
      />
    </div>
  )
}

export default ElementInput