import { gql } from '@apollo/client'

const clientsQuery = gql`
  query clients {
    clients {
      id
      name
    }
  }
`

const getUsersQuery = gql`
  query Users($limit: Int, $offset: Int, $ids: [ID!], $role: Role) {
    users(limit: $limit, offset: $offset, ids: $ids, role: $role) {
      id
      activated
      name
      firstName
      lastName
      employeeNumber
      email
      role
      attemptCount
      lastAttemptAt
      createdAt
      restricted
      anonymous
      externalId
    }
  }
`

const getUserByEmailQuery = gql`
  query getUserByEmail($email: String) {
    users(email: $email) {
      id
      email
      name
      firstName
      lastName
      role
      groupMemberships {
        group {
          id
          name
          courses {
            id
            name
          }
          publishedScenarios {
            id
            name
          }
        }
      }
    }
  }
`

const PREFERENCES_FIELDS = gql`
  fragment PreferencesFields on PagePreferences {
    filteredColumns
    showTable
    sortOn {
      value
      isAscending
    }
    period
  }
`
const userPreferencesQuery = gql`
  ${PREFERENCES_FIELDS}

  query {
    currentUser {
      preferences {
        media {
          ...PreferencesFields
        }
        scenarios {
          ...PreferencesFields
        }
        courses {
          ...PreferencesFields
        }
        trainees {
          ...PreferencesFields
        }
        clients {
          ...PreferencesFields
        }
        groups {
          ...PreferencesFields
        }
        transactions {
          ...PreferencesFields
        }
        products {
          ...PreferencesFields
        }
        sharedDevices {
          ...PreferencesFields
        }
        activities {
          ...PreferencesFields
        }
        attempts {
          ...PreferencesFields
        }
      }
    }
  }
`

const updateUserPreferencesMutation = gql`
  ${PREFERENCES_FIELDS}

  mutation UpdatePreferences(
    $media: PagePreferencesInput
    $scenarios: PagePreferencesInput
    $courses: PagePreferencesInput
    $trainees: PagePreferencesInput
    $clients: PagePreferencesInput
    $groups: PagePreferencesInput
    $products: PagePreferencesInput
    $transactions: PagePreferencesInput
    $sharedDevices: PagePreferencesInput
    $activities: PagePreferencesInput
    $attempts: PagePreferencesInput
  ) {
    updatePreferences(
      media: $media
      scenarios: $scenarios
      courses: $courses
      trainees: $trainees
      clients: $clients
      groups: $groups
      products: $products
      transactions: $transactions
      sharedDevices: $sharedDevices
      activities: $activities
      attempts: $attempts
    ) {
      preferences {
        media {
          ...PreferencesFields
        }
        scenarios {
          ...PreferencesFields
        }
        courses {
          ...PreferencesFields
        }
        trainees {
          ...PreferencesFields
        }
        clients {
          ...PreferencesFields
        }
        groups {
          ...PreferencesFields
        }
        transactions {
          ...PreferencesFields
        }
        products {
          ...PreferencesFields
        }
        sharedDevices {
          ...PreferencesFields
        }
        activities {
          ...PreferencesFields
        }
        attempts {
          ...PreferencesFields
        }
      }
    }
  }
`

const currentUserQuery = gql`
  query currentUser {
    currentUser {
      id
      email
      firstName
      lastName
      administrator
      otpRequired
      otpConfigured
      otpConfiguredAt
      ssoManaged
      avatar {
        medium
      }
    }
    currentClient {
      id
      name
      features
      primaryColor
      secondaryColor
      canPublishScenarios
    }
    currentRole
    currentPlan {
      features
    }
  }
`

const addUserMutation = gql`
  mutation addUserMutation(
    $firstName: String
    $lastName: String
    $role: RestrictedRole!
    $email: String!
    $employeeNumber: String
  ) {
    addUser(
      firstName: $firstName
      role: $role
      lastName: $lastName
      email: $email
      employeeNumber: $employeeNumber
    ) {
      user {
        id
        activated
        name
        firstName
        lastName
        employeeNumber
        email
        role
        attemptCount
        lastAttemptAt
        createdAt
        restricted
        externalId
      }
    }
  }
`

const activateUserMutation = gql`
  mutation activateUserMutation(
    $token: ID!
    $generalAndTermsOfUseAccepted: Boolean!
  ) {
    activateUser(
      token: $token
      generalAndTermsOfUseAccepted: $generalAndTermsOfUseAccepted
    ) {
      user {
        id
        ssoManaged
      }
    }
  }
`
const updateUserMutation = gql`
  mutation updateUserMutation(
    $firstName: String
    $lastName: String
    $userId: ID!
    $employeeNumber: String
    $role: RestrictedRole
  ) {
    updateUser(
      userId: $userId
      firstName: $firstName
      lastName: $lastName
      employeeNumber: $employeeNumber
      role: $role
    ) {
      user {
        id
        name
        firstName
        lastName
        role
        email
        employeeNumber
        attemptCount
        lastAttemptAt
        restricted
        externalId
      }
    }
  }
`
const updateProfileMutation = gql`
  mutation updateProfileMutation(
    $firstName: String
    $lastName: String
    $blobId: String
  ) {
    updateProfile(firstName: $firstName, lastName: $lastName, blobId: $blobId) {
      user {
        id
      }
    }
  }
`

const deleteUsersMutation = gql`
  mutation removeUserMutation($ids: [ID!]!) {
    removeUsers(ids: $ids) {
      ids
    }
  }
`
const importUsersMutation = gql`
  mutation importUsersMutation($blobId: String!, $groupIds: [ID!]!) {
    importUsers(blobId: $blobId, groupIds: $groupIds) {
      import {
        id
      }
    }
  }
`

const exportUsersMutation = gql`
  mutation exportUsers($kind: TraineeExportKind!, $userIds: [ID!]) {
    exportUsers(kind: $kind, userIds: $userIds) {
      success
    }
  }
`

const signInMutation = gql`
  mutation signIn(
    $email: String!
    $password: String!
    $otp: String
    $rememberMe: Boolean!
  ) {
    signIn(
      email: $email
      password: $password
      otp: $otp
      rememberMe: $rememberMe
    ) {
      user {
        id
        firstName
        lastName
      }
    }
  }
`

const resetPasswordMutation = gql`
  mutation resetPassword($email: String!) {
    resetPassword(email: $email) {
      success
    }
  }
`

const passkeyOptions = gql`
  query passkeyOptions {
    passkeyOptions
  }
`

const signInPasskeyMutation = gql`
  mutation signInPasskey($publicKeyCredential: String!) {
    signInPasskey(publicKeyCredential: $publicKeyCredential) {
      user {
        id
      }
    }
  }
`

const checkSsoConfigurationQuery = gql`
  query checkSsoConfiguration($email: String!) {
    ssoConfiguration(email: $email) {
      name
      domain
      initiationPath
      enforced
    }
  }
`

const updatePasswordMutation = gql`
  mutation updatePassword(
    $currentPassword: String!
    $password: String!
    $passwordConfirmation: String!
  ) {
    updatePassword(
      currentPassword: $currentPassword
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      user {
        id
        email
      }
    }
  }
`

const updatePasswordWithToken = gql`
  mutation updatePasswordWithToken(
    $token: ID!
    $password: String!
    $passwordConfirmation: String!
  ) {
    updatePasswordWithToken(
      token: $token
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      user {
        id
        email
      }
    }
  }
`

const enableOtpMutation = gql`
  mutation enableOtp($secret: String!, $otp: String!) {
    enableOtp(secret: $secret, otp: $otp) {
      user {
        id
        otpRequired
        otpConfigured
      }
    }
  }
`

const disableOtpMutation = gql`
  mutation disableOtp {
    disableOtp {
      user {
        id
        otpRequired
        otpConfigured
      }
    }
  }
`

const userActivitiesQuery = gql`
  query userActivities {
    userActivities {
      id
      action
      metadata {
        key
        value
      }
      geoCity
      geoCountry
      browserName
      browserVersion
      osName
      osFullVersion
      createdAt
    }
  }
`

const getUserNameByIdQuery = gql`
  query getUserByEmail($id: ID!) {
    users(id: $id) {
      id
      name
    }
  }
`

const createLoginSessionMutation = gql`
  mutation createLoginSession($scenarioId: ID) {
    createLoginSession(scenarioId: $scenarioId) {
      session {
        code
        url
        scenarios {
          name
        }
        state
      }
    }
  }
`

const createSessionBatchMutation = gql`
  mutation createSessionBatch($userIds: [ID!]!) {
    createSessionBatch(userIds: $userIds) {
      success
    }
  }
`

export {
  getUsersQuery,
  getUserByEmailQuery,
  currentUserQuery,
  addUserMutation,
  activateUserMutation,
  updateUserMutation,
  deleteUsersMutation,
  importUsersMutation,
  exportUsersMutation,
  clientsQuery,
  userPreferencesQuery,
  updateUserPreferencesMutation,
  updateProfileMutation,
  signInMutation,
  passkeyOptions,
  signInPasskeyMutation,
  resetPasswordMutation,
  checkSsoConfigurationQuery,
  updatePasswordMutation,
  updatePasswordWithToken,
  enableOtpMutation,
  disableOtpMutation,
  userActivitiesQuery,
  getUserNameByIdQuery,
  createLoginSessionMutation,
  createSessionBatchMutation,
}
