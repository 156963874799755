import { gql } from '@apollo/client'
import { PURCHASE_FIELDS } from './fragments'

const getBuyerTransactionsQuery = gql`
  ${PURCHASE_FIELDS}
  query getPurchasesAsBuyer {
    purchases(kind: BUYER) {
      ...PurchaseFields
    }
  }
`

const getSellerTransactionsQuery = gql`
  ${PURCHASE_FIELDS}
  query getPurchasesAsSeller {
    purchases(kind: SELLER) {
      ...PurchaseFields
    }
  }
`

const addPurchaseMutation = gql`
  ${PURCHASE_FIELDS}
  mutation addPurchase(
    $buyingClientId: ID!
    $productId: ID!
    $purchasedLicenseCount: Int
  ) {
    addPurchase(
      buyingClientId: $buyingClientId
      productId: $productId
      purchasedLicenseCount: $purchasedLicenseCount
    ) {
      purchase {
        ...PurchaseFields
      }
    }
  }
`

const cancelPurchaseMutation = gql`
  mutation cancelPurchase($id: ID!) {
    cancelPurchase(id: $id) {
      purchase {
        id
        buyingClient {
          name
        }
        productName
        subscriptionState
      }
    }
  }
`

const cancelPurchaseSubscriptionMutation = gql`
  mutation cancelPurchaseSubscription($id: ID!) {
    cancelPurchaseSubscription(id: $id) {
      purchase {
        id
        buyingClient {
          name
        }
        productName
        subscriptionState
      }
    }
  }
`

export {
  getBuyerTransactionsQuery,
  getSellerTransactionsQuery,
  addPurchaseMutation,
  cancelPurchaseMutation,
  cancelPurchaseSubscriptionMutation,
}
