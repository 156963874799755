import useCurrentUser from './useCurrentUser'

const useCurrentFeatures = () => {
  const [currentUser, currentClient, , currentPlan] = useCurrentUser()

  const isAdministrator = () => {
    return currentUser?.administrator
  }

  const hasFeature = (feature, adminOverride = false) => {
    if (adminOverride && isAdministrator) {
      return true
    }

    if (!currentClient || !currentPlan) {
      return false
    }

    return (
      currentPlan.features.includes(feature) ||
      currentClient.features.includes(feature)
    )
  }

  return { hasFeature, isAdministrator }
}

export default useCurrentFeatures
