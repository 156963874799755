import { PropTypes } from 'prop-types'
import moment from 'moment'
import { default as S } from './lang/en.json'

export const convertMediaType = (stereoscopic, kind) => {
  return `${stereoscopic !== null && !['FLAT_VIDEO', 'FLAT_IMAGE'].includes(kind) ? S.dataTypes.transform.stereoscopic[stereoscopic.toString()] : ''} ${
    kind ? S.dataTypes.transform.type[kind] : ''
  }`
}
export const convertMilliSeconds = (ms, formatAsSeconds) => {
  if (!Number(ms) && ms !== 0) {
    return null
  }

  const s = ms / 1000
  let ss = s % 60
  let mm = Math.floor(s / 60)
  mm %= 60
  if (mm < 10) {
    mm = `0${mm}`
  }

  if (ss < 10) {
    if (formatAsSeconds) {
      ss = `0${Math.floor(ss)}`
    } else {
      ss = `0${ss.toFixed(2)}`
    }
  } else if (!formatAsSeconds) {
    ss = ss.toFixed(2)
  } else {
    ss = Math.floor(ss)
  }

  return `${mm}:${ss}`
}
convertMilliSeconds.propTypes = {
  ms: PropTypes.Number,
}

export const convertResolution = (width) => {
  switch (true) {
    case width > 1440:
      return `${(width / 960).toFixed(1)}K`
    case width <= 1440:
      return '720P'
    default:
      return '-'
  }
}
convertResolution.propTypes = {
  width: PropTypes.Number,
}

export const convertSize = (bytes) => {
  bytes || (bytes = 0)
  switch (true) {
    case bytes > 1000000000:
      return `${(bytes / 1000000000).toFixed(1)} GB`
    case bytes > 1000000:
      return `${(bytes / 1000000).toFixed(0)} MB`
    default:
      return `${(bytes / 1000).toFixed(0)} KB`
  }
}
convertSize.propTypes = {
  bytes: PropTypes.Number,
}

export const dateFormatWithoutTZConversion = (createdAt, format) => {
  if (!createdAt) return null

  return moment(createdAt)
    .utcOffset(0, false)
    .format(format || 'DD MMM YYYY, HH:mm')
}
export const dateFormat = (createdAt, format, formatToday = true) => {
  if (!createdAt) return null

  if (
    formatToday &&
    moment().format('DD-MM-YY') === moment(createdAt).format('DD-MM-YY')
  ) {
    return `Today, ${moment(createdAt).format('HH:mm')}`
  }
  return moment(createdAt).format(format || 'DD MMM YYYY, HH:mm')
}

export const convertValuta = (value) => {
  return `€ ${value}`
}

export const sortOnKey = (array, key, isAscending) => {
  if (!array.length) return array

  // If key does not exist on array
  if (!array.some((o) => Object.keys(o).includes(key))) {
    //throw new Error('Key not found')
    console.error('Key not found:', key)
    return array
  }

  const sorted = array.sort((a, b) => {
    const an = a[key]?.value ?? a[key]
    const bn = b[key]?.value ?? b[key]

    if (!an) return -1
    if (!bn) return 1

    return an.toString().localeCompare(bn.toString(), 'en', { numeric: true })
  })
  return isAscending ? sorted.reverse() : sorted
}

export const convertDuration = (secs, showSeconds = true) => {
  if (!secs) return 0
  const sec_num = parseInt(secs)
  const h = Math.floor(sec_num / 3600)
  const m = Math.floor(sec_num / 60) % 60
  const s = showSeconds ? sec_num % 60 : 0

  return `${h ? `${h}h ` : ''}${m ? `${m}m ` : ''}${s ? `${s}s ` : ''}`
}

export const getPeriodStartDateTime = (
  format = '-30_days',
  now = new Date()
) => {
  const [duration, type] = format.split('_')
  let date = moment.utc(now).add(Number(duration), type)
  if (type === 'days')
    date = date.set({ hours: 0, minute: 0, second: 0, millisecond: 0 })
  else if (type === 'month')
    date = date.set({
      date: 1,
      hours: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    })
  else if (type === 'year')
    date = date.set({
      date: 1,
      month: 0,
      hours: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    })
  return date.toDate()
}

const NOW = new Date()
export const getPeriodEndDateTime = (format = '-30_days') => {
  if (!['-1_month', '-1_year'].includes(format)) return NOW

  const [, type] = format.split('_')

  let date = moment.utc()
  if (type === 'month')
    date = date.set({ date: 1, hours: 0, minute: 0, second: 0, millisecond: 0 })
  else if (type === 'year') {
    date = date.set({
      month: 0,
      date: 1,
      hours: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    })
  }
  return date.toDate()
}
