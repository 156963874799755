import React, { useState } from 'react'
import { FaEllipsisH } from 'react-icons/fa'
import Dropdown from '../UI/Menu/Dropdown'

const PurchaseOptionDropdown = ({
  id,
  subscription,
  currentPurchaseHandler,
}) => {
  const [isHovered, setIsHovered] = useState(false)

  const content = () => (
    <>
      {subscription ? (
        <li
          className="o-dropdown__list-item text-normal text-dark"
          data-open="cancel-subscription-modal"
          onClick={() => currentPurchaseHandler(id)}>
          Cancel subscription
        </li>
      ) : (
        <li
          className="o-dropdown__list-item text-normal text-dark"
          data-open="cancel-purchase-modal"
          onClick={() => currentPurchaseHandler(id)}>
          Cancel transaction
        </li>
      )}
    </>
  )

  if (isHovered) {
    return (
      <Dropdown
        id={`purchase-menu-${id}`}
        alignment="right"
        offset={{ left: '20px', top: '0px' }}
        button={
          <button
            data-toggle={`purchase-menu-${id}`}
            className="cursor-pointer secondary hollow button button-alt mb-0">
            <FaEllipsisH />
          </button>
        }>
        {content()}
      </Dropdown>
    )
  }

  return (
    <button
      className="cursor-pointer secondary hollow button button-alt mb-0"
      data-toggle={`purchase-menu-${id}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <FaEllipsisH />
    </button>
  )
}

export default PurchaseOptionDropdown
