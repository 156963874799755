import React from 'react'
import { FaCloudUploadAlt } from 'react-icons/fa'
import { FaPhotoFilm } from 'react-icons/fa6'
import DragAndDrop from '../UI/DragAndDrop'

const MediaEmptyState = ({ setUploadMediaItems, isLink }) => {
  if (isLink)
    return (
      <div
        data-testid="media-empty-state-container"
        className="o-table--page-container grid-container fluid">
        <div style={{ display: 'grid', placeItems: 'center', height: '80%' }}>
          <span className="text-center">
            <span
              className="o-table__empty--icon"
              style={{ width: '200px', height: '200px' }}>
              <FaPhotoFilm style={{ width: '80px' }} />
            </span>
            <h2 className="text-bold text-dark">
              There are no media files yet!
            </h2>
            <span className="text-stable-dark">
              Go to media page and upload your media files there.
            </span>
          </span>
        </div>
      </div>
    )
  return (
    <div
      data-testid="media-empty-state-container"
      className="o-table--page-container grid-container fluid">
      <div style={{ display: 'grid', placeItems: 'center', height: '80%' }}>
        <span className="text-center">
          <span className="o-table__empty--icon ">
            <FaCloudUploadAlt />
          </span>
          <h2 className="text-bold text-dark">There are no media files yet!</h2>
          <span
            className="text-stable-dark cursor-pointer text-underline"
            data-open="media-wrapper-uploadMediaModal">
            Click here to upload your first file
          </span>
        </span>
      </div>
    </div>
  )
}
export default MediaEmptyState
