import { useState } from 'react'
import { useQuery } from '@apollo/client'
import { currentUserQuery } from '../apollo/query/user'
import appsignal from '../utils/appsignal'

const useCurrentUser = () => {
  const [currentUser, setCurrentUser] = useState(null)
  const [currentClient, setCurrentClient] = useState(null)
  const [currentRole, setCurrentRole] = useState(null)
  const [currentPlan, setCurrentPlan] = useState(null)

  const decorator = (tags) => {
    return (span) => {
      span?.setTags(tags)
    }
  }

  const { refetch } = useQuery(currentUserQuery, {
    onCompleted: ({
      currentUser: u,
      currentRole: r,
      currentClient: c,
      currentPlan: p,
    }) => {
      setCurrentUser(u)
      setCurrentRole(r)
      setCurrentClient(c)
      setCurrentPlan(p)

      const tags = {
        user_id: u?.id,
        client_id: c?.id,
        role: r,
      }

      appsignal.addDecorator(decorator(tags))
    },
    notifyOnNetworkStatusChange: true,
  })

  return [currentUser, currentClient, currentRole, currentPlan, refetch]
}

export default useCurrentUser
