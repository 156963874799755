// import 'aframe'
// import 'aframe-rounded'
// import '../../../EditorV2/Video/Elements/partials/aframe-text-bubble'
// import '../../../EditorV2/Video/Elements/partials/aframe-button'
// import '../../../EditorV2/Video/Elements/partials/aframe-mpc'
// import '../../../EditorV2/Video/Elements/partials/aframe-scene-settings'
// import '../../../EditorV2/Video/Elements/partials/aframe-collider-check'
// import '../../../EditorV2/Video/Elements/partials/aframe-hideable-element'
// import '../../../EditorV2/Video/Elements/partials/aframe-collision-box'

import React, { useEffect, useRef, useState, useContext } from 'react'
import NorthOffsetLine from '../../../EditorV2/Video/Elements/partials/NorthOffsetLine'
import Information from '../../../EditorV2/Video/Elements/Information'
import Hotspot from '../../../EditorV2/Video/Elements/Hotspot'
import Direction from '../../../EditorV2/Video/Elements/Direction'
import MultipleChoice from '../../../EditorV2/Video/Elements/MultipleChoice'
import { VideoContext } from '../../../EditorV2/VideoContext'
import { moveCameraToPosition } from '../../../Editor/helpers/controls'
import emptyVideo from './assets/empty-video.png'
import Delay from '../../../../utils/helpers/Delay'
import { MediumContext } from '../../../Media/Modal/MediumContext'
import useVideoPlayer from '../../../EditorV2/useVideoPlayer'
import Nadir from './Nadir'
import Tooltip from '../../../EditorV2/Video/Elements/Tooltip'
import Button from '../../../EditorV2/Video/Elements/Button'
import { GROUP_CHILD_ELEMENT_TYPES } from '../../helpers/elementHelper'
import FlowControls from '../FlowControls'
import useSelectedNode from '../../hooks/useSelectedNode'
import Mediapanel from '../../../EditorV2/Video/Elements/Mediapanel'

const Player = ({ fov = 70, scenarioData }) => {
  const [state, setState] = useContext(VideoContext)
  const {
    currentVideoRef,
    controls: [controls, setControls],
    time: [, setTime],
  } = useContext(MediumContext)
  const selectedNode = useSelectedNode()
  const sceneState = selectedNode.data
  const { video } = sceneState
  const { setShowElements, setShowHotspots } = useVideoPlayer()
  const [currentVideo, setCurrentVideo] = useState(null)
  const stateRef = useRef(sceneState)
  const rotation = '0 -90 0'

  useEffect(() => {
    // update state ref on state change
    stateRef.current = sceneState
  }, [sceneState])

  const loadedHandler = () => {
    const { trimEnabled, trimStart } = video
    setState((s) => ({
      ...s,
      video: currentVideoRef.current,
    }))
    setCurrentVideo(currentVideoRef.current)
    currentVideoRef.current.currentTime = trimEnabled ? trimStart : 0

    if (sceneState.northOffsetEnabled)
      moveCameraToPosition(0, -sceneState.northOffset || 0, true)
  }

  const pauseHandler = () => {
    setShowElements(true)
    setShowHotspots(true)
  }

  const loopHandler = () => {
    if (!stateRef.current.videoLoopEnabled) {
      return
    }

    if (video.trimEnabled) {
      currentVideoRef.current.currentTime =
        stateRef.current.videoLoop + Number(video.trimStart)
      currentVideoRef.current.play()
    } else {
      currentVideoRef.current.currentTime = stateRef.current.videoLoop
      currentVideoRef.current.play()
    }
  }

  const timeUpdateHandler = () => {
    if (
      currentVideoRef.current.paused ||
      currentVideoRef.current.currentTime === 0 ||
      (video.trimEnabled &&
        currentVideoRef.current.currentTime === video.trimStart)
    ) {
      setShowHotspots(true)
      return setShowElements(true)
    }
    if (video.trimEnabled) {
      setShowHotspots(
        currentVideoRef.current.currentTime - Number(video.trimStart) >=
          (stateRef.current.hotspotHintEnabled
            ? Number(stateRef.current.hotspotHintAfter)
            : 0) +
            Number(stateRef.current.elementsFadeIn)
      )

      return setShowElements(
        Number(currentVideoRef.current.currentTime) - Number(video.trimStart) >=
          Number(stateRef.current.elementsFadeIn)
      )
    }

    setShowHotspots(
      currentVideoRef.current.currentTime >=
        (stateRef.current.hotspotHintEnabled
          ? Number(stateRef.current.hotspotHintAfter)
          : 0) +
          Number(stateRef.current.elementsFadeIn)
    )
    return setShowElements(
      currentVideoRef.current.currentTime >=
        Number(stateRef.current.elementsFadeIn)
    )
  }
  useEffect(() => {
    // Ensure the video shows an image in VR mode
    if (video) {
      if (
        currentVideoRef?.current &&
        currentVideoRef.current !== currentVideo
      ) {
        currentVideoRef.current.addEventListener('pause', pauseHandler, true)
        currentVideoRef.current.addEventListener('ended', loopHandler, true)
        currentVideoRef.current.addEventListener(
          'ended-on-trim',
          loopHandler,
          true
        )
        currentVideoRef.current.addEventListener(
          'loadedmetadata',
          loadedHandler,
          true
        )
        currentVideoRef.current.addEventListener(
          'timeupdate',
          timeUpdateHandler,
          true
        )
      }
    }
  }, [video, sceneState, currentVideo])

  const elements = () => {
    const allAnswerElements = sceneState.elements.filter(
      (el) => GROUP_CHILD_ELEMENT_TYPES.includes(el.kind) && el.groupUuid
    )

    const elements = sceneState.elements.filter(
      (el) => !allAnswerElements.find((aEl) => aEl.id === el.id)
    )

    const renderElement = (el) => {
      const kind = el.groupKind || el.kind
      const id = `${el.kind}-${el.id}`
      const answerElements = allAnswerElements.filter(
        (a) => a.groupUuid === el.groupUuid
      )

      switch (kind) {
        case 'INFORMATION':
          return (
            <Information
              key={id}
              id={id}
              element={el}
              childElements={answerElements}
              state={state}
            />
          )
        case 'HOTSPOT':
          return <Hotspot key={id} id={id} element={el} state={state} />
        case 'MEDIAPANEL':
          return <Mediapanel key={id} id={id} element={el} state={state} />
        case 'DIRECTION':
          return <Direction key={id} id={id} element={el} state={state} />
        case 'MPC':
          return (
            <MultipleChoice
              key={id}
              id={id}
              element={el}
              childElements={answerElements}
              state={state}
            />
          )
        case 'TOOLTIP':
          return <Tooltip key={id} id={id} element={el} state={state} />
        case 'BUTTON':
          return <Button key={id} id={id} element={el} state={state} />
        default:
          return null
      }
    }
    return elements.map((el) => renderElement(el))
  }

  const noVideoPlayer = () => (
    <Delay>
      <a-assets>
        <img
          id="grid"
          src={emptyVideo}
          alt="sky gradient"
          crossOrigin="anonymous"
        />
      </a-assets>
      <a-videosphere
        id="a-videosphere"
        src="#grid"
        rotation={rotation}></a-videosphere>
    </Delay>
  )

  const player = () => {
    return (
      <div className="video-element--container h-100">
        <a-scene
          scene-settings
          raycaster="objects: .clickable"
          xr-mode-ui="enterVRButton: #fullScreenVRButton">
          <a id="fullScreenVRButton" />
          {/* Assets */}
          <a-assets>
            <img
              crossOrigin="anonymous"
              src={scenarioData.client.logoVrUrl}
              id="client-vr-logo"
              alt=""
            />
            <video
              ref={currentVideoRef}
              id="video"
              hidden={!sceneState?.video?.playUrl}
              src={sceneState?.video?.playUrl}
              crossOrigin="anonymous"
              onTimeUpdate={(e) => {
                const el = e.currentTarget
                setTime(el.currentTime * 1000)
                setControls({ ...controls, isPlaying: !el.paused && !el.ended })
              }}
            />
          </a-assets>
          {/* Camera */}
          <a-camera
            wasd-controls-enabled="false"
            id="a-camera"
            fov={fov}
            position="0 0 0"
            look-controls="reverseMouseDrag: true"
            zoom="1"></a-camera>
          {/* Background video */}
          {sceneState?.video?.playUrl ? (
            <a-videosphere
              id="a-videosphere"
              src="#video"
              rotation={rotation}
            />
          ) : (
            noVideoPlayer()
          )}

          {sceneState.northOffsetEnabled && <NorthOffsetLine />}
          {/* Elements */}
          {elements()}

          {!scenarioData.hideNadir && <Nadir />}
        </a-scene>
      </div>
    )
  }

  return (
    <div id="videoplayer" className="h-100 flex-container align-center">
      {player()}
      <FlowControls showVideoEditor={true} />
    </div>
  )
}
export default Player
