import React, { Suspense, lazy, useState } from 'react'
import Modal from '../../UI/Modal'
import MediaTable from '../../FlowV2/components/Media/MediaTable'

const MediaModal = ({
  id,
  appendTo,
  scenarioId,
  isLink,
  selectedId,
  onLinkClickCallback = () => {},
  selectedFilterOption,
  hideTypeFilter,
}) => {
  const handleLinkClick = (mediumId) => {
    onLinkClickCallback(mediumId)
    $(`#${id}`).foundation('close')
  }

  return (
    <Modal id={id} appendTo={appendTo} width="90%" closable={false}>
      <div
        className="bg-light pt-3 pb-3 border-radius"
        style={{ height: '80vh' }}>
        <div className="h-100" style={{ position: 'relative' }}>
          <MediaTable
            id="media-modal-table"
            isLink={isLink}
            scenarioId={scenarioId}
            selectedId={selectedId}
            onLinkClick={handleLinkClick}
            selectedFilterOption={selectedFilterOption}
            hideTypeFilter={hideTypeFilter}
          />
        </div>
      </div>
    </Modal>
  )
}
export default MediaModal
