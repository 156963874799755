import React, { lazy, Suspense, useContext, useState } from 'react'
import Dropdown from '../../../UI/Menu/Dropdown'
import { FaChevronRight, FaEllipsisH, FaTrash } from 'react-icons/fa'
import {
  ELEMENT_TYPES,
  getElementSlideType,
  getSlideTypeIcon,
  GROUP_PARENT_ELEMENT_TYPES,
  INTERACTIVE_ELEMENT_TYPES,
  MEDIA_SLIDE_TYPES,
} from '../../helpers/elementHelper'
import useAddUpdateElement from '../../hooks/useAddUpdateElement'
import ButtonGroup from '../../../UI/Form/ButtonGroup/ButtonGroup'
import DataTooltip from '../../../UI/DataTooltip'
import useFoundation from '../../../../hooks/useFoundation'
import { ScenarioEditorContext } from '../../context/ScenarioEditorProvider'
import { capitalized } from '../../../../utils/helpers/javascript'
import MediaModal from '../../../Media/Modal/MediaModal'

const TYPES_HAS_CHANGE = ['DIRECTION', 'HOTSPOT', 'BUTTON']

const ElementCardDropdown = ({
  element,
  position = 'bottom',
  deleteDisabled,
  deleteDisabledText = '',
  last,
}) => {
  const { updateElement } = useAddUpdateElement()
  const ref = useFoundation()
  const [selectedFilterOption, setSelectedFilterOption] = useState('')
  const { scenario } = useContext(ScenarioEditorContext)
  const { updateElementSlideDetails } = useAddUpdateElement()

  const handleChangeElement = (e) => {
    const type = e.currentTarget.dataset.type
    updateElement({
      variables: {
        ...element,
        kind: type,
      },
    })
  }

  const handleEndingElement = () => {
    const newElement = {
      ...element,
      linkToEnding: !element.linkToEnding,
      linkToId: null,
      randomizedLinkToIds: [],
    }
    updateElement({
      variables: newElement,
    })
  }

  const isParent =
    element.groupKind && GROUP_PARENT_ELEMENT_TYPES.includes(element.kind)

  const isInformationButton =
    element.groupKind === 'INFORMATION' && element.kind === 'BUTTON'

  const getDeleteButton = () => (
    <>
      <FaTrash className="mr-1 text-assertive" />
      Delete{' '}
      {ELEMENT_TYPES.find(
        (el) => el.type === (isParent ? element.groupKind : element.kind)
      ).title.toLowerCase()}
    </>
  )

  const isChangableSlideAttachment =
    element.kind === 'SLIDE' &&
    ['IMAGE', 'VIDEO'].includes(getElementSlideType(element))
  const slideType = getElementSlideType(element)

  const onLinkClickCallback = async (mediumId) => {
    updateElementSlideDetails({
      ...element,
      mediumId,
    })
  }

  const handleChangeSlideType = (e) => {
    const oldType = getElementSlideType(element)
    const destType = e.target.dataset.type
    switch (destType) {
      case 'TEXT':
        return updateElementSlideDetails({
          ...element,
          mediumId: null,
        })
      case 'IMAGE':
      case 'VIDEO':
        setSelectedFilterOption(` FLAT_${destType}`)
        $(`#media-modal-card-element-${element.id}`).foundation('open')
        if (oldType === 'TEXT')
          return updateElementSlideDetails({
            ...element,
            description: '',
          })
    }
  }

  return (
    <div ref={ref}>
      <Dropdown
        id={`element-menu-${element.id}`}
        alignment="right"
        offset={{ left: '20px', top: '0px' }}
        minWidth={200}
        position={position}
        button={
          element.groupUuid || last ? (
            <button
              className="pt-1 pb-1 pl-1-5 pr-1-5 o-button o-button--square-small o-button--secondary o-button--no-active o-button--light text-bold flex-container align-middle mb-0"
              data-toggle={`element-menu-${element.id}`}>
              <FaEllipsisH />
            </button>
          ) : (
            <ButtonGroup.Button
              last
              className="pt-1 pb-1 pl-1-5 pr-1-5 o-button o-button--square-small o-button--light o-button--no-active text-bold flex-container align-middle mb-0"
              data-toggle={`element-menu-${element.id}`}>
              <FaEllipsisH />
            </ButtonGroup.Button>
          )
        }>
        <>
          {element.kind === 'SLIDE' &&
            getElementSlideType(element) !== 'DRAFT' && (
              <>
                {isChangableSlideAttachment && (
                  <li
                    className="o-dropdown__list-item flex-container align-middle text-dark"
                    data-open={`media-modal-card-element-${element.id}`}
                    onClick={() =>
                      setSelectedFilterOption(` FLAT_${slideType}`)
                    }>
                    Change {slideType.toLowerCase()} file
                  </li>
                )}
                <li
                  className="o-dropdown__list-item o-dropdown__item--open-dropdown opens-left"
                  style={{ position: 'relative' }}>
                  <div className="flex-container align-middle align-justify">
                    <span>Change slide type</span>
                    <FaChevronRight />
                  </div>
                  <ul
                    className="o-dropdown o-dropdown--right"
                    style={{ minWidth: '200px', top: '-5px' }}>
                    {MEDIA_SLIDE_TYPES.filter((type) => slideType !== type).map(
                      (type) => (
                        <li
                          key={type}
                          data-type={type}
                          className="o-dropdown__list-item text-normal text-dark flex-container align-middle"
                          onClick={handleChangeSlideType}>
                          {getSlideTypeIcon(type)}
                          <span className="ml-1 u-capitalize">
                            {capitalized(type)}
                          </span>
                        </li>
                      )
                    )}
                  </ul>
                </li>
                <li className="o-dropdown__divider" />
              </>
            )}

          {INTERACTIVE_ELEMENT_TYPES.includes(element.kind) && (
            <>
              <li className="o-dropdown__list-item">
                <div className="flex-container align-middle align-justify">
                  <span>Ending</span>
                  <div className="o-switch">
                    <input
                      type="checkbox"
                      data-testid="ending-input"
                      name="linkToId"
                      checked={element.linkToEnding || false}
                      readOnly
                      id={`linkToId-${element.id}`}
                      className="o-switch__input"
                    />
                    <label
                      onClick={handleEndingElement}
                      htmlFor={`linkToId-${element.id}`}
                      className="o-switch__paddle"></label>
                  </div>
                </div>
              </li>
              <li
                className="o-dropdown__list-item"
                onClick={() => {
                  $('#randomizer-modal')
                    .data({
                      element,
                    })
                    .foundation('open')
                }}>
                Randomizer
              </li>
              {!isInformationButton && <li className="o-dropdown__divider" />}
            </>
          )}

          {!isInformationButton && (
            <>
              {TYPES_HAS_CHANGE.includes(element.kind) && (
                <>
                  <li
                    className="o-dropdown__list-item o-dropdown__item--open-dropdown opens-left"
                    style={{ position: 'relative' }}>
                    <div className="flex-container align-middle align-justify">
                      <span>Change element</span>
                      <FaChevronRight />
                    </div>
                    <ul
                      className="o-dropdown o-dropdown--right"
                      style={{ minWidth: '200px', top: '-5px' }}>
                      {TYPES_HAS_CHANGE.filter((el) => element.kind !== el).map(
                        (el) => {
                          const elRow = ELEMENT_TYPES.find((e) => e.type === el)
                          return (
                            <li
                              key={el}
                              onClick={handleChangeElement}
                              data-type={el}
                              className="o-dropdown__list-item text-normal text-dark flex-container align-middle">
                              {elRow.icon}
                              <span className="ml-1">{elRow.title}</span>
                            </li>
                          )
                        }
                      )}
                    </ul>
                  </li>
                  <li className="o-dropdown__divider" />
                </>
              )}

              {deleteDisabled ? (
                <DataTooltip position="left" title={deleteDisabledText}>
                  <li className="o-dropdown__list-item flex-container align-middle text-dark disabled">
                    {getDeleteButton()}
                  </li>
                </DataTooltip>
              ) : (
                <li
                  className="o-dropdown__list-item flex-container align-middle text-dark"
                  onClick={() => {
                    $('#delete-element-modal')
                      .data({
                        element,
                      })
                      .foundation('open')
                  }}>
                  {getDeleteButton()}
                </li>
              )}
            </>
          )}
        </>
      </Dropdown>

      <MediaModal
        id={`media-modal-card-element-${element.id}`}
        appendTo="#sceneario-editor-sidebar"
        isLink={true}
        scenarioId={scenario.id}
        onLinkClickCallback={onLinkClickCallback}
        selectedFilterOption={selectedFilterOption}
        hideTypeFilter={true}
      />
    </div>
  )
}

export default ElementCardDropdown
