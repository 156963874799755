import React, { useContext } from 'react'
import { MediumContext } from '../MediumContext'
import { convertMilliSeconds } from '../../../../utils/format'
import './TimeIndicator.scss'
import VideoTrimIcon from '../../../../utils/helpers/VideoTrimIcon'
import DataTooltip from '../../../UI/DataTooltip'

const TimeIndicator = ({ editorControls = false }) => {
  const {
    medium: [medium],
    time: [time],
  } = useContext(MediumContext)

  const getDuration = () => {
    const mediumDuration = medium.duration
    if (editorControls && medium.trimEnabled) {
      const endingTime = medium.trimEnd * 1000 || mediumDuration
      const duration = endingTime - (medium.trimStart * 1000 || 0)
      const formattedDuration = convertMilliSeconds(duration, true)
      return formattedDuration
    }
    return convertMilliSeconds(mediumDuration, true)
  }

  if (!medium) return null

  return (
    <div className="cell auto grid-x">
      <p className="mr-1 mb-0 text-dark">
        <span className="time-wrapper">{convertMilliSeconds(time, true)}</span>
        <span className="text-stable-dark">/ {getDuration()}</span>
      </p>
      {editorControls && medium.trimEnabled && (
        <DataTooltip title="Video is trimmed">
          <VideoTrimIcon />
        </DataTooltip>
      )}
    </div>
  )
}

export default TimeIndicator
